import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'

import { Layout, Footer, Login, MetaTags, VideoPlayer } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { lightRed, orange } from '../styles/colors'

import logo from '../assets/images/logo-watermark.png'

import { isAuthenticated } from '../services/auth';
import { media } from '../styles/utils';

class Index extends React.Component {

	state = {
        
	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressLanding.acf_json),
            globalData: JSON.parse(this.props.data.wordpressOptions.acf_json)
		})
	}
	
    componentDidMount = () => {
        if (isAuthenticated()) navigate('app/home');
    }

    render() {       
        const { data, globalData } = this.state;
        let meta_description = globalData.meta_description_default;
        
        if (isAuthenticated()) { 
            return <></> 
        }

        console.log(data)

		return (
			<Layout>

                {globalData && (
                    <MetaTags 
                        title={`Adventures in the Field`}
                        description={meta_description}
                        url={`https://adventuresinthefield.com`}
                        //image={globalData.social_sharing_image.sizes.medium2}
                    />
                )}

                <Wrapper
                    bgImage={data.background_image.sizes.full_width}
                >
                    <Container>

                        <Logo 
                            to={'/'}
                        />
                        
                        <Content>
                            <Info>
                                <Title>Adventures in the Field</Title>
                                <Subheading>{data.tagline}</Subheading>
                       
                                <VideoPlayer
                                    video_type='embed'
                                    video_embed_src={data.video_embed_src}
                                />
                       
                            </Info>
                            <Login/>
                        </Content> 

                        <ExtraContent>
                            <Description
                                dangerouslySetInnerHTML={{__html: data.content_text}}
                            />
                            <Description
                                dangerouslySetInnerHTML={{__html: data.content_text_2}}
                            />
                        </ExtraContent>   

                    </Container>
                </Wrapper>
                <Footer/>
			</Layout>	
		)
	}
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``

// Layput

const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Container = styled.div`
	${container}
	${padding}
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;

    padding-top: 130px;
	padding-bottom: 120px;

    ${media.phone`
        padding-top: 0;
        padding-bottom: 80px;
	`}
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
`


// Content box

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.76); 
    border-radius: 24px;
    max-width: 945px;
    padding: 35px 94px 47px 35px;  
    box-sizing: border-box;

    ${media.phone`
        flex-direction: column;
        margin-top: 140px;
        padding: 32px 20px;
    `}

    ${Subheading} {
        font-size: 18px;
        letter-spacing: 0.025em;
        line-height: 1.5em;
        font-family: 'LatoWebBoldItalic';
        color: #636363;
        margin-bottom: 40px;
        transform: translateY(-10px);

        ${media.phone`
            font-size: 13px;
            text-align: center;
            font-family: 'LatoWebItalic';
            margin-bottom: 20px;
            font-weight: 400;
            letter-spacing: none;
        `}
    }
`

const Info = styled.div`
    flex: 0 1 50%;

    display: flex;
    flex-direction: column;

    ${media.phone`
        margin-bottom: 48px;
    `}
`


const Title = styled.div`
    text-transform: uppercase;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 6px;
    color: ${orange};
    max-width: 320px;

    ${media.phone`
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    `}
`


const Links = styled.div`
    max-width: 300px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
`

const AuthLink = styled(Link)`
    font-size: 20px;
    padding: 0 20px;
`

const VideoContainer = styled.div`
    margin-top: 30px;
    width: 500px;
    height: 300px;
    background: rgba(255,255,255,0.5);
`

// Extra Content

const ExtraContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.76); 
    border-radius: 24px;
    max-width: 945px;
    padding: 28px 50px;  
    box-sizing: border-box;
    margin-top: 21px;

    ${media.phone`
        flex-direction: column;
        margin-top: 30px;
        padding: 20px 35px 48px;
    `}

    ${Description} {
        flex: 0 1 calc(50% - 20px);
        flex-basis: calc(50% - 20px);
       
        h2 {
            font-size: 18px;
            letter-spacing: 0.3em;
            color: #636363;
            text-transform: uppercase;
            font-style: normal;
            margin-bottom: 24px;

            ${media.phone`
                text-align: center;
                font-weight: 400;
            `}
        }
        
        p {
            color: #999999;
            font-size: 16px;
            line-height: 24px;      
            font-family: 'LatoWebItalic';
            font-weight: 400;

            ${media.phone`
                font-size: 14px;
                line-height: 1.5em;
            `}
        }

        a {
            color: ${orange};
            border-bottom: 1px solid ${orange};
            ${hoverState};
        }

        &:nth-child(2) {
            margin-top: 63px;

            ${media.phone`
                margin-top: 0;
            `}
        } 
    }
`

export const query = graphql`
	query {
		wordpressLanding {
			acf_json	
		}
        wordpressOptions {
            acf_json
        }
	}
`

export default Index

